import {html, css, LitElement} from 'lit';
import {Utilities} from "../../base/service/utilities";

export class BlockonomicsWalletBalance extends LitElement {
    static properties = {
        balances: {type: Object},
    }

    constructor() {
        super();
        this.addresses = [];
    }

    createRenderRoot() {
        return this;
    }


    render() {
        const totalConfirmed = this.balances.reduce((acc, address) => acc + parseFloat(address.confirmed), 0);
        return html`
          <div class="w-full max-w-[1024px] mx-auto borer-[1px] border-primary-light dark:border-secondary-lighter">
            <div class="h-[52px] flex justify-between p-4 bg-primary-light dark:bg-secondary-lighter rounded-t-lg font-semibold text-md">
              <h2>Address</h2>
              <div class="flex">
                <div>
                  <h2>Balance (BTC)</h2>
                </div>
                <div class="w-[21px] mx-2"></div>
              </div>
            </div>
            ${this.balances.map((balance) =>
                html`
                  <div class="flex justify-between p-4 border-[1px] border-primary-light dark:border-secondary-lighter text-sm sm:text-[16px]">
                    <div class="text-left break-all">${balance.addr}</div>
                    <div class="flex">
                      <div class="w-24 sm:w-36 text-right">
                        <h2>BTC
                          ${Utilities.assertIsNumber(balance.confirmed) ? Utilities.satsToBtc(balance.confirmed) : balance.confirmed}
                        </h2>
                      </div>
                      <div class="w-[21px] mx-2"></div>
                    </div>
                  </div>`
            )}
            <div class="h-[52px] flex justify-end p-3 bg-primary-light dark:bg-secondary-lighter rounded-b-lg font-semibold text-md">
              <h2>Total Balance</h2>
              <h2 class="text-primary ml-6">BTC
                ${Utilities.assertIsNumber(totalConfirmed) ? Utilities.satsToBtc(totalConfirmed) : totalConfirmed}</h2>
              <div class="w-[21px] mx-2"></div>
            </div>
          </div>
        `;
    }
}

customElements.define('blockonomics-wallet-balance', BlockonomicsWalletBalance);