import {html, LitElement} from 'lit';
import {Utilities} from "../../base/service/utilities";
import {BlockonomicsAPI} from "../service/BlockonomicsAPI";
import {BlockonomicsCopiable} from "../../base/component/blockonomics-copiable";

export class BlockonomicsTransaction extends LitElement {
    static properties = {
        timeHumanReadable: {state: true},
        valueHumanReadable: {state: true},
        stateShareMenu: {state: true},
        addr: {type: String},
        isExpanded: {type: Boolean},
        value: {type: Number},
        time: {type: Number},
        addresses: {type: Array},
        txid: {type: String},
        fee: {type: Number},
        feeUsd: {type: Number},
        status: {type: String},
        confirmationTime: {type: Number},
        rbf: {type: String},
        size: {type: Number},
        vin: {type: Array},
        vout: {type: Array}
    }

    constructor() {
        super();
        this.stateShareMenu = false;
        this.isExpanded = false;
        this.vin = [];
        this.vout = [];
    }

    createRenderRoot() {
        return this;
    }

    updated(changedProperties) {
        if (changedProperties.has('time')) {
            this.timeHumanReadable = Utilities.timestampToHumanReadable(this.time, false);
        }
        if (changedProperties.has('value')) {
            this.valueHumanReadable = Utilities.satsToBtc(this.value);
        }
        if (changedProperties.has('isExpanded')) {
            if (changedProperties.get('isExpanded') === false && this.isExpanded === true) {
                this.updateTransaction();
            }
        }
    }

    async toggleExpand() {
        this.isExpanded = !this.isExpanded;
    }

    async updateTransaction() {
        let service = new BlockonomicsAPI();
        let transaction = await service.getTransactionDetail(this.txid, this.addr);
        this.fee = transaction.fee;
        this.feeUsd = transaction.fee_usd;
        this.status = transaction.status;
        this.confirmationTime = transaction.conf_time;
        this.size = transaction.size;
        this.rbf = transaction.rbf || '-';
        this.vin = transaction.args_txin;
        this.vout = transaction.args_txout;
    }

    async share() {
        //Todo: Consider abstract share component to utilties
        if (navigator.share) {
            const shareData = {
                title: "Bitcoin Transaction - Blockonomics",
                text: `Transaction detail for ${this.transaction ? this.transaction.txid : ''}`,
                url: this.getShareUrl(),
            };

            try {
                await navigator.share(shareData);
            } catch (err) {
                console.error(err);
            }
        } else {
            this.stateShareMenu = !this.stateShareMenu;
        }
    }

    async closeShareMenu() {
        this.stateShareMenu = false
    }

    async copyUrl() {
        try {
            await navigator.clipboard.writeText(this.getShareUrl());
            const alert = new toaster;
            alert.notify('URL copied');
        } catch (err) {
            console.error(err);
        }
    }

    getShareUrl() {
        let url = `${window.location.origin}/#/search?q=${this.txid}`
        return this.addr ? `${url}&addr=${encodeURI(this.addr)}` : url;
    }

    async copyTxid() {
        try {
            await navigator.clipboard.writeText(this.txid);
            const alert = new toaster;
            alert.notify('Transaction ID copied');
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        return html`
          <div class="w-full max-w-[1024px]">
            <div @click="${this.toggleExpand}"
                 class="h-[112px] p-4 bg-primary-light dark:bg-secondary-lighter cursor-pointer font-mono flex justify-between items-start rounded-t-lg ${this.isExpanded === true ? '' : 'rounded-b-lg'}">
              <div class="flex flex-col items-start h-[80px] justify-center w-4/5">
                <div class="flex justify-start items-center">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.92407 12.8715C4.69385 12.8715 4.4967 12.7896 4.33262 12.6258C4.16882 12.4617 4.08691 12.2646 4.08691 12.0344V6.17431C4.08691 5.94409 4.16882 5.74708 4.33262 5.58328C4.4967 5.41919 4.69385 5.33715 4.92407 5.33715H5.34264V4.5H6.1798V5.33715H9.52841V4.5H10.3656V5.33715H10.7841C11.0144 5.33715 11.2115 5.41919 11.3756 5.58328C11.5394 5.74708 11.6213 5.94409 11.6213 6.17431V12.0344C11.6213 12.2646 11.5394 12.4617 11.3756 12.6258C11.2115 12.7896 11.0144 12.8715 10.7841 12.8715H4.92407ZM4.92407 12.0344H10.7841V7.84861H4.92407V12.0344ZM4.92407 7.01146H10.7841V6.17431H4.92407V7.01146Z"
                        fill="white"/>
                    <path
                        d="M4.92407 12.8715C4.69385 12.8715 4.4967 12.7896 4.33262 12.6258C4.16882 12.4617 4.08691 12.2646 4.08691 12.0344V6.17431C4.08691 5.94409 4.16882 5.74708 4.33262 5.58328C4.4967 5.41919 4.69385 5.33715 4.92407 5.33715H5.34264V4.5H6.1798V5.33715H9.52841V4.5H10.3656V5.33715H10.7841C11.0144 5.33715 11.2115 5.41919 11.3756 5.58328C11.5394 5.74708 11.6213 5.94409 11.6213 6.17431V12.0344C11.6213 12.2646 11.5394 12.4617 11.3756 12.6258C11.2115 12.7896 11.0144 12.8715 10.7841 12.8715H4.92407ZM4.92407 12.0344H10.7841V7.84861H4.92407V12.0344ZM4.92407 7.01146H10.7841V6.17431H4.92407V7.01146Z"
                        fill="white"/>
                  </svg>
                  <div class="text-xs">${this.timeHumanReadable}</div>
                </div>
                ${this.addr ? html`
                  <div
                      class="text-lg font-semibold sm:text-2xl pl-1 ${this.value >= 0 ? 'text-primary' : 'text-[#EB5757]'} ${this.status === 'Confirmed' ? '' : 'text-orange-400'}">
                    BTC ${(this.value >= 0 ? '+' : '') + this.valueHumanReadable}
                  </div>
                ` : html`
                  <div class="text-lg sm:text-xl font-thin pl-1 overflow-hidden text-ellipsis w-4/5">
                    ${this.txid}
                  </div>
                `}
                ${this.status === 'Confirmed' ? '' : html`
                  <div class="flex justify-start items-center">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="mr-1">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.93325 3.91863V7.82011L11.0696 9.89502L10.5536 10.4261L8.19255 8.13303V3.91871L8.93325 3.91863ZM14.0869 8C14.0869 4.96239 11.6245 2.5 8.58691 2.5C5.54909 2.5 3.08691 4.96239 3.08691 8C3.08691 11.0376 5.5493 13.5 8.58691 13.5C11.6243 13.5 14.0869 11.0376 14.0869 8Z"
                            fill="#F0B356"/>
                    </svg>
                    <div class="text-xs">
                      ${this.status === 'Unconfirmed' ? 'Pending' : this.status}
                      ${this.confirmationTime && this.status === 'Unconfirmed' ? `~ ${this.confirmationTime} min` : ''}
                    </div>
                  </div>
                `}
              </div>
              <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     class="cursor-pointer ${this.isExpanded === true ? 'rotate-180' : ''}"
                ">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1C5.925 1 0.999999 5.925 0.999999 12C1 18.075 5.925 23 12 23ZM15.293 9.793C15.4816 9.61084 15.7342 9.51005 15.9964 9.51233C16.2586 9.51461 16.5094 9.61977 16.6948 9.80518C16.8802 9.99059 16.9854 10.2414 16.9877 10.5036C16.99 10.7658 16.8892 11.0184 16.707 11.207L12.707 15.207C12.5195 15.3945 12.2652 15.4998 12 15.4998C11.7348 15.4998 11.4805 15.3945 11.293 15.207L7.293 11.207C7.11084 11.0184 7.01005 10.7658 7.01232 10.5036C7.0146 10.2414 7.11977 9.99059 7.30518 9.80518C7.49059 9.61978 7.7414 9.51461 8.0036 9.51233C8.26579 9.51005 8.5184 9.61084 8.707 9.793L12 13.086L15.293 9.793Z"
                      fill="white"/>
                </svg>
              </div>
            </div>
            <div
                class="border-primary-light dark:border-secondary-lighter overflow-hidden ${this.isExpanded === true ? 'h-auto p-6 border-4' : 'h-0 p-0 border-0'}">
              <div class="text-left">
                <div class="flex flex-row justify-between items-center mb-6">
                  <h2 class="text-md sm:text-xl font-sans font-bold">Transaction Information</h2>
                  <div class="relative">
                    <button
                        class="bg-primary cursor-pointer font-sans p-2 text-black rounded-md font-bold flex justify-between items-center"
                        @click="${this.share}">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="sm:mr-1">
                        <mask id="mask0_541_30194" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                              width="24" height="24">
                          <rect width="24" height="24" fill="#D9D9D9"/>
                        </mask>
                        <g mask="url(#mask0_541_30194)">
                          <path
                              d="M4.39922 19.5256C4.03255 19.6923 3.67855 19.6633 3.33722 19.4386C2.99522 19.2133 2.82422 18.8923 2.82422 18.4756V15.0506C2.82422 14.7839 2.89922 14.5463 3.04922 14.3376C3.19922 14.1296 3.39922 14.0006 3.64922 13.9506L11.0992 12.0006L3.64922 10.0506C3.39922 10.0006 3.19922 9.87128 3.04922 9.66261C2.89922 9.45461 2.82422 9.21728 2.82422 8.95061V5.52561C2.82422 5.10895 2.99522 4.78795 3.33722 4.56261C3.67855 4.33795 4.03255 4.30895 4.39922 4.47561L19.7492 10.9506C20.2159 11.1506 20.4492 11.5006 20.4492 12.0006C20.4492 12.5006 20.2159 12.8506 19.7492 13.0506L4.39922 19.5256Z"
                              fill="#1C1B1F"/>
                        </g>
                      </svg>
                      <span class="hidden sm:inline-block">Share</span>
                    </button>
                    <div @click="${this.closeShareMenu}"
                         class="absolute ${this.stateShareMenu ? 'show' : 'hidden'} right-0 mt-2 w-[144px] rounded-md shadow-lg bg-white z-10">
                      <div class="py-1">
                        <button @click="${this.copyUrl}"
                                class="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                               stroke="currentColor" class="w-4 h-4 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"/>
                          </svg>
                          Copy URL
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-5 text-xs sm:text-[16px] font-mono">
                  <div class="col-span-5 sm:col-span-1 font-bold mb-2">Status</div>
                  <div class="col-span-5 sm:col-span-4 mb-6 sm:mb-2 font-[400]">
                    ${this.status ? html`
                      <span
                          class="${this.status === 'Confirmed' ? 'text-primary' : 'text-[#F0B356]'}">${this.status === 'Unconfirmed' ? 'Pending' : this.status}</span>
                    ` : html`
                      <div class="animate-pulse">
                        <div class="bg-gray-200 rounded w-2/3 h-5"></div>
                      </div>
                    `}
                  </div>
                  <div class="col-span-5 sm:col-span-1 font-bold mb-2">Transaction ID</div>
                  <div class="col-span-5 sm:col-span-4 mb-6 sm:mb-2 flex justify-between items-center bg-primary-lighter dark:bg-secondary-lighter shadow rounded-2xl">
                    <blockonomics-copiable class="w-full" value="${this.txid}"></blockonomics-copiable>
                  </div>
                  <div class="col-span-5 sm:col-span-1 font-bold mb-2">Transaction Fees</div>
                  <div class="col-span-5 sm:col-span-4 mb-6 sm:mb-2 font-[400]">
                    ${this.fee ? `BTC ${Utilities.satsToBtc(this.fee)}/($${this.feeUsd?.toFixed(2)})` : html`
                      <div class="animate-pulse">
                        <div class="bg-gray-200 rounded w-2/3 h-5"></div>
                      </div>
                    `}
                  </div>
                  <div class="col-span-5 sm:col-span-1 font-bold mb-2">Transaction Size</div>
                  <div class="col-span-5 sm:col-span-4 mb-6 sm:mb-2 font-[400]">
                    ${this.size ? `${this.size} bytes` : html`
                      <div class="animate-pulse">
                        <div class="bg-gray-200 rounded w-2/3 h-5"></div>
                      </div>
                    `}
                  </div>
                  <div class="col-span-5 sm:col-span-1 font-bold mb-2">Fee Rate</div>
                  <div class="col-span-5 sm:col-span-4 mb-6 sm:mb-2 font-[400]">
                    ${this.fee && this.size ? `${(this.fee / this.size).toFixed(2)} satoshi per bytes` : html`
                      <div class="animate-pulse">
                        <div class="bg-gray-200 rounded w-2/3 h-5"></div>
                      </div>
                    `}
                  </div>
                  <div class="col-span-5 sm:col-span-1 font-bold mb-2">RBF</div>
                  <div class="col-span-5 sm:col-span-4 mb-6 sm:mb-2 font-[400]">
                    ${this.rbf ? `${this.rbf}` : html`
                      <div class="animate-pulse">
                        <div class="bg-gray-200 rounded w-2/3 h-5"></div>
                      </div>
                    `}
                  </div>
                </div>
                <hr class="border-2 border-primary-light dark:border-secondary-lighter my-2">
                <div class="grid grid-cols-2 gap-x-4 font-mono text-sm sm:text-[16px]">
                  <div class="col-span-2 sm:col-span-1 mb-4">
                    <h2 class="text-md font-sans font-bold mb-6">Input</h2>
                    <ul>
                      ${this.vin.map((tx) => html`
                        <li class="mb-2 ${tx.highlight ? 'bg-primary-light dark:bg-secondary-lighter p-4' : ''}">
                          <a href="/#/search?q=${tx.address}" class="text-primary break-all mb-1"
                             target="_blank">${tx.address}</a>
                          <div>
                            ${tx.value} BTC
                          </div>
                        </li>
                      `)}
                    </ul>
                  </div>
                  <div class="col-span-2 sm:col-span-1">
                    <h2 class="text-md font-sans font-bold mb-6">Output</h2>
                    <ul>
                      ${this.vout.map((tx) => html`
                        <li class="mb-2 ${tx.highlight ? 'bg-primary-light dark:bg-secondary-lighter p-4' : ''}">
                          <a href="/#/search?q=${tx.address}" class="text-primary break-all mb-1"
                             target="_blank">${tx.address}</a>
                          <div>
                            ${tx.value} BTC
                          </div>
                        </li>
                      `)}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `;
    }
}

customElements.define('blockonomics-transaction', BlockonomicsTransaction);