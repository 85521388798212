export class OAuth {
    google(xsrfCookie, redirectUrl) {
        // Google's OAuth 2.0 endpoint for requesting an access token
        const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/auth';
        const url = new URL(oauth2Endpoint);
        let state = {
            '_xsrf': xsrfCookie,
            'redirect_url': redirectUrl
        };

        // Base64 encode state so that we can pass it through oAtuh callback
        state = btoa(JSON.stringify(state));

        // Parameters to pass to OAuth 2.0 endpoint.
        url.searchParams.set('client_id', '408977657487-2ldjugr63avjojd8k2jbgtcb0tdvetgd.apps.googleusercontent.com')
        url.searchParams.set('redirect_uri', window.location.origin + '/api/oauth2callback')
        url.searchParams.set('response_type', 'code')
        url.searchParams.set('scope', 'email')
        url.searchParams.set('state', state)

        window.location.href = url.toString()
    }
}