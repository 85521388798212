/**
 * search.js is the entrypoint for search functionalities
 * **/

import './base/component/blockonomics-header';
import './base/component/blockonomics-footer';
import './base/component/theme-switcher';
import './search/component/blockonomics-omnisearch';
import {Auth} from './base/service/auth';
import {Router} from './base/service/router';
import { Toaster } from "./base/service/toaster";

window.router = Router;
window.toaster = Toaster;
window.auth = new Auth();
document.addEventListener('DOMContentLoaded', () => {
    window.auth.check();
});

export const applyTheme = () => {
  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', applyTheme);

applyTheme();