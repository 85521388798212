import {Http} from "./http"
import {Utilities} from "./utilities";
import {OAuth} from "./oauth";

export class Auth {
  constructor(baseURL = '/api/') {
    this.baseURL = baseURL;
  }

  async check() {
    const header = document.querySelector('blockonomics-header');
    if (header) {
      try {
        const credential = await this.info();
        header.login(credential.email);
      } catch (e) {
        header.guest();
      }
    }
  }

  info() {
    return Http.get(`${this.baseURL}info`)
      .then(credential => {
        return credential;
      }).catch(error => {
        throw new Error(error);
      });
  }

  login(emailid, password) {
    const clientChallenge = window.run_challenge();
    const redirectPath = this.getRedirectUrl();
    return Http.post(`${this.baseURL}login_user`, {
      emailid,
      password: password,
      client_challenge: clientChallenge,
      redirect_url: redirectPath
    }, {
      headers: {'X-Xsrftoken': this.getCSRFCookie()}
    }).then(credential => {
      this.info()
        .then(info => {
          window.location.href = credential.redirect_url;
        })
        .catch(error => {
          window.toaster.notify('Please allow cookies via browser settings to continue');
        });
    }).catch(error => {
      throw error;
    });
  }

  register(emailid, password) {

    const clientChallenge = window.run_challenge();
    const search_params = window.location.search ? window.location.search : window.location.hash.replace("#/", "");
    let activation_url = '/register#/activate' + search_params;

    return Http.post(`${this.baseURL}register`, {
      emailid,
      password: password,
      client_challenge: clientChallenge,
    }, {
      headers: {'X-Xsrftoken': this.getCSRFCookie()}
    }).then(stringifiedData => {
      let data = JSON.parse(stringifiedData)
      const login_url = window.location.origin + '/login#/' + search_params;
      document.getElementById("login-link").setAttribute("href", login_url);
      const userId = data.user
      activation_url += (activation_url.match(/[\?]/g) ? '&' : '?') + `user=${userId}`;
      window.location.href = activation_url;
    }).catch(error => {
      throw error;
    });
  }

  activate(code, user) {
    const clientChallenge = window.run_challenge();

    return Http.post(`${this.baseURL}activate`, {
      code,
      user,
      client_challenge: clientChallenge,
    }, {
      headers: {'X-Xsrftoken': this.getCSRFCookie()}
    }).then(data => {
      return data
    }).catch(error => {
      throw error;
    });
  }

  resendActivate(user) {
    const clientChallenge = window.run_challenge();

    return Http.post(`${this.baseURL}resend_activation`, {
      user,
      client_challenge: clientChallenge,
    }, {
      headers: {'X-Xsrftoken': this.getCSRFCookie()}
    }).then(() => {
      return {
        message: "Activation code resent"
      }
    }).catch(error => {
      throw error;
    });
  }

  googleOAuth() {
    let oAuth = new OAuth();
    oAuth.google(this.getCSRFCookie(), this.getRedirectUrl());
  }

  getRedirectUrl() {
    const search_params = window.location.search ? window.location.search : window.location.hash.replace("#/", "");
    let param_string = search_params.split('?')[1];
    const params = new URLSearchParams(param_string);
    const redirect_url = params.get('redirect_url');
    if (redirect_url) {
      return redirect_url
    }

    return 'dashboard#/'
  }

  getCSRFCookie() {
    return Utilities.getCookie('_xsrf');
  }

  async setCSRFCookie() {
    try {
      await Http.get('/api/set_csrf_cookie');
    } catch (e) {
      console.log(e);
    }
  }
}