import {Http} from "../../base/service/http"

export class BlockonomicsAPI {
    constructor(baseURL = '/api/') {
        this.baseURL = baseURL;
    }

    async getBalance(keyword) {
        const postData = {
            addr: keyword,
            client_challenge: window.run_challenge()
        };

        try {
            let payload = await Http.post(`${this.baseURL}balance`, postData);
            return payload.response;
        } catch (error) {
            throw error;
        }
    }

    async getTransactions(keyword) {
        const postData = {
            addr: keyword,
            client_challenge: window.run_challenge()
        };

        try {
            let payload = await Http.post(`${this.baseURL}searchhistory`, postData);
            payload.history.map(tx => {
                tx.type = 'Confirmed'
            });
            payload.pending.map(tx => {
                if (tx.status === 1) {
                    tx.type = 'Partially Confirmed'
                } else {
                    tx.type = 'Unconfirmed'
                }
            });
            let mergedTransactions = [...payload.history, ...payload.pending];
            return mergedTransactions.sort((a, b) => b.time - a.time).slice(0, 200);
        } catch (error) {
            throw error;
        }
    }

    async getTransactionDetail(txid, addr = null) {
        try {
            let url = `${this.baseURL}tx_detail?txid=${txid}`;
            if (addr) {
                url = `${url}&addr=${addr}`
            }
            return await Http.get(url);
        } catch (error) {
            throw error;
        }
    }
}