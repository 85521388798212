import {format} from "date-fns";


export class Utilities {
  static getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }

    return null;
  }

  static timestampToHumanReadable(timestamp, commaSeperator = true) {
    const date = new Date(timestamp * 1000); // convert to milliseconds
    let dateString = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);

    if (!commaSeperator) {
      dateString = dateString.replace(',', '');
    }

    return dateString;
  }

  static satsToBtc(sats) {
    return sats / 1.0E8
  }

  static assertIsNumber(value) {
    return typeof value == 'number' && !isNaN(value)
  }

  static epochToDateTime(epochTime) {
    const date = new Date(epochTime * 1000);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return `${month} ${day.toString().padStart(2, '0')}, ${year} ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
  }

  static truncateWords(string, length = 5) {
    if (string.length > length) {
      const truncatedString = string.substring(0, length)
      return `${truncatedString}...`;
    }
    return string;
  }

  static truncateAddress(string, startLength = 6, endLength = 6) {
    if (string.length > startLength + endLength) {
      const startString = string.substring(0, startLength);
      const endString = string.substring(string.length - endLength);
      return `${startString}...${endString}`;
    }
    return string;
  }

  static getUrlOrigin(url) {
    if (url) {
        return new URL(url).origin;
      }

      return "";
  }

  static formatTimestampToDate(timestamp, dateFormat = "dd MMM. yyyy") {
    if (!timestamp) {
      return 'N/A';
    }
    const date = new Date(timestamp * 1000);
    return format(date, dateFormat);
  }

  static formatCurrency(amount, format = 'en-US', minimumFractionDigits = 2, maximumFractionDigits = 2) {
    return amount.toLocaleString(format, { minimumFractionDigits, maximumFractionDigits });
  }

  static parseServerErrorResponse(message) {
    const colonIndex = message.indexOf(':');
    const startQuoteIndex = message.indexOf("'", colonIndex);
    const endQuoteIndex = message.indexOf("'", startQuoteIndex + 1);
    if (colonIndex !== -1 && startQuoteIndex !== -1 && endQuoteIndex !== -1) {
      return message.substring(startQuoteIndex + 1, endQuoteIndex).split(':')[1];
    }
    return null;
  }
}
