export class Toaster {
    //Todo: Improve toaster utility to be more flexible
    constructor() {
        this.container = document.createElement('div');
        this.container.classList.add('toaster-container');
        this.container.style.position = 'fixed';
        this.container.style.padding = '16px';
        this.container.style.width = '100%';
        this.container.style.bottom = '-50px';
        this.container.style.right = '0';
        this.container.style.zIndex = '999';
        document.body.appendChild(this.container);
    }

    notify(message) {
        const notification = document.createElement('div');
        const classes = "slide-in-from-left m-auto mb-1 flex justify-between items-center w-fit max-w-xs lg:max-w-[68rem] p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800".split(' ');
        notification.classList.add(...classes);
        notification.setAttribute('role', 'alert');
        notification.innerHTML = `
                    <div class="pl-4 text-sm font-normal break-words">${message}</div>
                    <button onclick="this.parentElement.remove()" type="button" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-default" aria-label="Close">
                        <span class="sr-only">Close</span>
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </button>
            `;
        this.container.appendChild(notification);

        setTimeout(() => {
            notification.remove();
        }, 5000);
    }
}