export class Router {
    routes = {};
    pageContainerClass = 'blockonomics-page';

    constructor(routes, pageContainerClass = 'blockonomics-page') {
        this.pageContainerClass = pageContainerClass;
        this.routes = routes ? routes : {
            '/': home,
        };
        window.addEventListener('hashchange', this.handleHashChange);
    }

    handleHashChange = () => {
        const path = window.location.hash.substring(1); // Get path from hash
        const segments = path.split('?');
        if (window.location.hash) {
            const routeHandler = this.routes[segments[0]] || this.notFound; // Get handler function, or use notFound if no matching route
            routeHandler(); // Execute handler function
        }
    }

    showPage = (name) => {
        this.hideAllPage();
        document.getElementById(name).classList.remove('hidden');
    }

    hideAllPage = () => {
        let pages = document.querySelectorAll(`.${this.pageContainerClass}`);
        pages.forEach((page) => {
            page.classList.add('hidden')
        })
    }

    notFound = () => {
        console.log('Page not found');
    }
}