import {html, css, LitElement} from 'lit';
import {camelCase, startCase} from 'lodash-es';
import {BlockonomicsAPI} from "../service/BlockonomicsAPI";
import {BlockonomicsWalletBalance} from "./blockonomics-wallet-balance";
import {BlockonomicsTransaction} from "./blockonomics-transaction";
import {Http} from "../../base/service/http";
import {Utilities} from "../../base/service/utilities";


class BlockonomicsOmnisearch extends LitElement {
    static properties = {
        searchURI: {state: true},
        stateFocus: {state: true},
        stateActive: {state: true},
        stateLoading: {state: true},
        stateError: {state: true},
        stateHasResult: {state: true},
        errorMessage: {type: String},
        keyword: {type: String},
        action: {type: String},
        balances: {state: true},
        transactions: {state: true},
        transaction: {state: true},
    }

    constructor() {
        super();
        this.keyword = '';
        this.errorMessage = '';
        this.searchURI = '/#/search'
        this.balances = [];
        this.transactions = [];
        this.stateFocus = false;
        this.stateActive = false;
        this.stateLoading = false;
        this.stateError = false;
        this.stateHasResult = false;
    }

    createRenderRoot() {
        return this;
    }

    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('keyword')) {
            const isFirstSearch = !changedProperties.get('keyword');
            this.debouncedSearch(!isFirstSearch);
        }
    }

    handleFocus() {
        this.stateFocus = true;
    }

    handleBlur() {
        setTimeout(() => {
        this.stateFocus = false;
        }, 100);
        if (this.stateFocus === false && this.keyword === '') {
            this.setState('active', false);
        }
    }

    handleKeyDown(event) {
        if (event.key === 'Enter' || event.keyCode === 13) {
            this.search();
        }
    }

    handleInput(event) {
        this.keyword = event.target.value;
    }

    clearInput(exceptKeyword = false) {
        if (!exceptKeyword) {
            this.keyword = '';
            window.location = this.searchURI;
        }

        this.balances = [];
        this.transactions = [];
        this.transaction = null;
        this.stateLoading = false;
        this.clearError();
        this.setState('active', false);
        this.setState('has result', false);
    }

    clearError() {
        this.errorMessage = '';
        this.setState('error', false);
    }

    setState(label, state = true) {
        let property = 'state' + startCase(camelCase(label)).replace(/ /g, '');
        this[property] = state;
        this.dispatchEvent(new CustomEvent('state-changed', {
            detail: {
                state: camelCase(label),
                value: this[property]
            },
        }));
    }

    async search(updateURI = false) {
        if (!this.keyword) {
            return;
        }

        this.clearInput(true);
        this.clearError();
        this.setState('active');
        if (updateURI || Http.queryParams().toString() === '') {
            window.location = `${this.searchURI}?q=${this.keyword}`;
        }

        if (this.keyword.trim().length === 64) {
            await this.searchTx()
        } else {
            await this.searchAddress()
        }
    }

    async searchTx() {
        let service = new BlockonomicsAPI();
        this.stateLoading = true;
        try {
            this.transaction = await service.getTransactionDetail(
                this.keyword,
                Http.queryParams().get('addr'));
            this.setState('has result');
        } catch (error) {
            console.error(error.message);
            this.errorMessage = error.data ? error.data.message : error.message;
            this.setState('error');
        } finally {
            this.stateLoading = false;
        }
    }

    async searchAddress() {
        let service = new BlockonomicsAPI();
        this.stateLoading = true;
        try {
            this.balances = await service.getBalance(this.keyword);
            this.transactions = await service.getTransactions(this.keyword);
            this.setState('has result');
        } catch (error) {
            this.errorMessage = error.data ? error.data.message : error.message;
            this.setState('error');
        } finally {
            this.stateLoading = false;
        }
    }

    truncateXpub(string) {
        let words = string.split(' ');
        let truncatedWords = words.map((word) => word.length > 100 ? Utilities.truncateAddress(word) : word);
        return truncatedWords.join(" ");
    }

    debounce(fn, delay = 300) {
        let timeoutID;
        return function (...args) {
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => fn.apply(this, args), delay);
        };
    }

    debouncedSearch = this.debounce(function (updateURI) {
        this.stateFocus = false;
        this.search(updateURI);
    }, 500).bind(this);


    render() {
        return html`
          <div class="mb-9 w-full max-w-[656px] mx-auto relative">
            <div class="relative z-10">
              <div class="w-full">
                <input .value="${this.keyword}"
                       @input="${this.handleInput}"
                       @focus="${this.handleFocus}"
                       @blur="${this.handleBlur}"
                       @keydown="${this.handleKeyDown}"
                       class="w-full p-[18px] sm:p-[24px] pr-[86px] md:pr-[114px] h-[72px] text-[0.65em] sm:text-sm text-ellipsis text-gray-900 font-bold outline-none rounded-lg shadow-lg"
                       placeholder="Search Bitcoin address, transaction ID, XPUB">
                <div class="absolute right-0 bottom-0 h-full">
                  ${this.keyword === '' ? '' : html`
                    <button type="button"
                            aria-label="Clear Input"
                            @click="${() => this.clearInput(false)}"
                            class="bg-white text-center w-[32px] h-[72px]">
                      <svg class="mx-auto text-gray-400" width="24" height="24" xmlns="http://www.w3.org/2000/svg"
                           fill="none"
                           viewBox="0 0 24 24"
                           stroke-width="1.5"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                      </svg>
                    </button>
                  `}
                  <button type="button"
                          aria-label="Search"
                          @click="${this.search}"
                          class="bg-white text-center w-[52px] h-[72px] pr-2 rounded-r-lg">
                    <svg class="mx-auto text-gray-400" width="24" height="24" xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div
                class="w-full ${this.stateFocus ? 'h-[218px]' : 'h-[0]'} top-[10px] absolute bg-white rounded-lg transition-all pt-[62px] px-8 overflow-hidden text-[#AFAFAF] font-sans font-semibold text-xs text-left">
              <p class="pt-[41px]">You can search for your xpub to view your entire wallet balance.
                <a href="https://help.blockonomics.co/support/solutions/articles/33000248743-bitcoin-wallet-and-xpub"
                   target="_blank" class="text-primary">What is an Xpub?</a>
              </p>
              <p class="pt-[31px]">Search multiple addresses at once by separating with spaces.</p>
            </div>
          </div>

          <div class="container py-6 justify-center ${this.stateLoading ? 'flex' : 'hidden'}">
            <svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <style>.spinner_P7sC {
                fill: #fff;
                transform-origin: center;
                animation: spinner_svv2 .75s infinite linear
              }

              @keyframes spinner_svv2 {
                100% {
                  transform: rotate(360deg)
                }
              }</style>
              <path
                  d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
                  class="spinner_P7sC"/>
            </svg>
          </div>

          ${this.stateError === false ? '' : html`
            <div class="text-red-500 mb-3 mx-auto max-w-4xl text-center break-word">
              ${this.truncateXpub(this.errorMessage)}
            </div>
          `}

          ${!this.transaction ? '' : html`
            <div class="container mt-9">
              <div class="w-full max-w-[1024px] mx-auto">
                <div
                    class="mb-[24px] font-mono flex flex-col items-start sm:flex-row sm:justify-between sm:items-center">
                  <h2 class="text-xl">Transaction</h2>
                </div>
                <blockonomics-transaction class="block mb-2"
                                          isExpanded
                                          addr="${Http.queryParams().get('addr')}"
                                          value="${this.transaction.value}"
                                          time="${this.transaction.time}"
                                          txid="${this.keyword}"
                                          fee="${this.transaction.fee}"
                                          feeUsd="${this.transaction.fee_usd || 0}"
                                          size="${this.transaction.size}"
                                          status="${this.transaction.status}"
                                          confirmationTime="${this.transaction.conf_time}"
                                          rbf="${this.transaction.rbf || '-'}"
                                          .vin="${this.transaction.args_txin}"
                                          .vout="${this.transaction.args_txout}">
                </blockonomics-transaction>
              </div>
            </div>
          `}

          ${this.balances.length === 0 ? '' : html`
            <div class="container">
              <blockonomics-wallet-balance .balances="${this.balances}" class="mx-auto"></blockonomics-wallet-balance>
            </div>
          `}

          ${this.transactions.length === 0 ? html`
            ${!this.stateHasResult || this.transaction ? '' : html`
              <div class="container mt-9">
                <div class="w-full max-w-[1024px] mx-auto">
                  <div
                      class="mb-[24px] font-mono flex flex-col items-start sm:flex-row sm:justify-between sm:items-center">
                    <h2 class="text-xl">Transaction History</h2>
                  </div>
                  <div class="bg-primary-light dark:bg-secondary-lighter dark:text-white p-12 rounded-lg">
                    <h3 class="font-mono text-xl">No transactions</h3>
                  </div>
                </div>
              </div>
            `}
          ` : html`
            <div class="container mt-9">
              <div class="w-full max-w-[1024px] mx-auto">
                <div
                    class="mb-[24px] font-mono flex flex-col items-start sm:flex-row sm:justify-between sm:items-start">
                  <h2 class="text-xl">Transaction History</h2>
                  <div class="text-sm text-left md:text-right">
                    ${this.transactions.length === 200 ? '200+' : this.transactions.length} records
                    ${this.transactions.length === 200 ? html`<br class="hidden md:block">(Truncated to last 200)` : ''}
                  </div>
                </div>
                ${this.transactions.map((transaction) =>
                    html`
                      <blockonomics-transaction class="block mb-2"
                                                addr="${this.keyword}"
                                                status="${transaction.type}"
                                                value="${transaction.value}"
                                                time="${transaction.time}"
                                                confirmationTime="${transaction.conf_time ? transaction.conf_time : 0}"
                                                txid="${transaction.txid}">
                      </blockonomics-transaction>`
                )}
                <div class="text-md font-mono text-center my-12">
                  To be able to track, see more transactions and get notifications.<br>
                  Please <a href="https://www.blockonomics.co/register#/" class="text-primary">Sign Up</a>.
                </div>
              </div>
            </div>
          `}
        `;
    }
}

customElements.define('blockonomics-omnisearch', BlockonomicsOmnisearch);