import {LitElement, html} from "lit";
import {tick, copy} from './icons';

export class BlockonomicsCopiable extends LitElement {

    static properties = {
        value: {type: String},
        label: {type: String},
        success: {type: String},
        delay: {type: Number},
        stateCopying: { state: true }
    };
  
  constructor() {
    super();
    this.showShadow = false;
  }

    firstUpdated(changedProperties) {
        this.success = this.success || 'Copied';
        this.delay = parseInt(this.delay) || 2000;
    }

    createRenderRoot() {
        return this;
    }

    async copy() {
        this.stateCopying = true;
        await navigator.clipboard.writeText(this.value);
        this.dispatchEvent(new CustomEvent('success', {
            detail: {
                value: this.value
            },
        }));
        setTimeout(() => {
            this.stateCopying = false;
        }, this.delay)
    }

    render() {
        return html`
          <style>
            .blockonomics-copiable__no-scrollbar::-webkit-scrollbar {
              display: none; /* for Chrome, Safari, and Opera */
            }

            .blockonomics-copiable__no-scrollbar {
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
            }
          </style>
          <div 
            class="flex justify-between items-center px-3 py-2 rounded-xl"
          >
            <div class="w-full min-h-[38px] rounded-md flex items-center max-w-[90%]">
              ${this.stateCopying ? html`
                <div class="w-full flex justify-center items-center gap-2 font-sans font-bold">
                  <span class="font-sans">${this.success}</span>
                  <div class="ml-1 w-[17px] h-[17px]">${tick}</div>
                </div>
              ` : html`
                <div class="flex justify-start overflow-x-scroll blockonomics-copiable__no-scrollbar">
                    ${this.label ? this.label : this.value}
                </div>
              `}
            </div>
            <button
              class="ml-2 cursor-pointer transform transition-transform duration-150 hover:scale-110 active:scale-90"
              @click=${this.copy}>
              <div class="w-[19px]">${copy}</div>
            </button>
          </div>
        `;
    }
}

customElements.define('blockonomics-copiable', BlockonomicsCopiable);