import {html, css, LitElement} from 'lit';

export class BlockonomicsFooter extends LitElement {
    createRenderRoot() {
        return this;
    }

    render() {
        return html`
          <footer
              class="bg-primary-light dark:bg-secondary md:py-[64px] md:px-[64px] py-[50px] text-black dark:text-white">
            <div
                class="grid grid-cols-2 gap-[64px] mx-auto w-[90%] md:flex md:flex-row md:justify-between">
              <div class="flex flex-col justify-between col-span-2 md:col-span-1">
                <div>
                  <div class="inline-block dark:hidden">
                    <img
                        src="/img/blockonomics_black_logo.svg"
                        width="215"
                        height="40"
                        alt="Blockonomics logo"
                    />
                  </div>
                  <div class="hidden dark:inline-block">
                    <img
                        src="/img/blockonomics_white_logo.svg"
                        width="215"
                        height="40"
                        alt="Blockonomics logo"
                    />
                  </div>
                  <div>
                    <ul class="flex items-center">
                      <li class="mb-2 p-2">
                        <a href="https://www.facebook.com/pages/Blockonomics/1619324884975855" title="Facebook"
                           target="_blank">
                          <svg width="24" height="24" viewBox="0 0 25 24"
                               class="fill-secondary-darker dark:fill-gray-500 hover:fill-primary-dark:hover:fill-white"
                               xmlns="http://www.w3.org/2000/svg">
                            <path id="Vector"
                                  d="M13.2399 22V12.8919H16.3127L16.7694 9.32578H13.2399V7.05432C13.2399 6.02527 13.5267 5.32071 15.0036 5.32071H16.875V2.14132C15.9644 2.04374 15.0492 1.99662 14.1334 2.00019C11.4174 2.00019 9.5527 3.65823 9.5527 6.70204V9.31912H6.5V12.8852H9.55937V22H13.2399Z"/>
                          </svg>
                        </a>
                      </li>
                      <li class="mb-2 p-2">
                        <a href="https://twitter.com/blockonomics_co" title="Twitter" target="_blank"
                           class="fill-secondary-darker dark:fill-gray-500 hover:fill-primary-dark:hover:fill-white">
                          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333333 333333"
                               shape-rendering="geometricPrecision"
                               text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd"
                               clip-rule="evenodd">
                            <path
                                d="M333328 63295c-12254 5480-25456 9122-39241 10745 14123-8458 24924-21861 30080-37819-13200 7807-27871 13533-43416 16596-12499-13281-30252-21537-49919-21537-37762 0-68336 30591-68336 68330 0 5326 591 10537 1748 15562-56820-2880-107194-30081-140915-71467-6049 10435-9250 22300-9250 34367v8c0 23696 12031 44654 30389 56876-11202-333-21739-3457-30991-8519v854c0 33138 23554 60789 54852 67039-5734 1557-11787 2417-18023 2417-4417 0-8673-455-12905-1224 8742 27139 33975 46923 63857 47500-23430 18356-52858 29286-84939 29286-5537 0-10931-339-16318-984 30326 19458 66251 30727 104844 30727 125735 0 194551-104198 194551-194543 0-3002-67-5911-191-8852 13354-9553 24932-21609 34097-35333l31-31-6 4z"/>
                          </svg>
                        </a>
                      </li>
                      <li class="mb-2 p-2">
                        <a href="https://www.youtube.com/channel/UC_r4KjaRoPG55_gK-7hQCsw" title="Youtube"
                           target="_blank">
                          <svg width="30" height="24" class="fill-secondary-darker dark:fill-gray-500 hover:fill-primary-dark:hover:fill-white"
                               xmlns="http://www.w3.org/2000/svg"
                               viewBox="-35.20005 -41.33325 305.0671 247.9995">
                            <path
                                d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85"/>
                            <path d="M93.333 117.559l61.333-34.89-61.333-34.894z" class="fill-white dark:fill-secondary-darker"/>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <p>© 2024 Blockonomics</p>
                </div>
              </div>
              <div class="flex flex-col gap-3">
                <h6 class="font-bold text-accent-gray dark:text-[#8A9197] text-[14px] md:text-base">PRODUCTS</h6>
                <ul class="flex flex-col gap-4">
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/#/search">
                      Bitcoin Lookup
                    </a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/invoicecreate/">
                      Invoice
                    </a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/merchants">Merchants</a>
                  </li>
                </ul>
              </div>
              <div class="flex flex-col gap-3">
                <h6 class="font-bold text-accent-gray dark:text-[#8A9197] text-[14px] md:text-base">DEVELOPERS</h6>
                <ul class="flex flex-col gap-4">
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/api-documentation">API Documentation</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="https://devlibrary.blockonomics.co/">
                      Tutorials
                    </a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/about">Platform Status</a>
                  </li>
                </ul>
              </div>
              <div class="flex flex-col gap-3">
                <h6 class="font-bold text-accent-gray dark:text-[#8A9197] text-[14px] md:text-base">COMPANY</h6>
                <ul class="flex flex-col gap-4">
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/about">About Us</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="https://wellfound.com/company/blockonomics">Careers</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/referral">Affiliates</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="https://tally.so/r/3N7MBp">Contact Sales</a>
                  </li>
                </ul>
              </div>
              <div class="flex flex-col gap-3">
                <h6 class="font-bold text-accent-gray dark:text-[#8A9197] text-[14px] md:text-base">RESOURCES</h6>
                <ul class="flex flex-col gap-4">
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="https://help.blockonomics.co/support/home">Help Center</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="https://community.blockonomics.co/">Community Forum</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="https://insights.blockonomics.co/">Blog</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/integrations">E-Commerce integrations</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li class="hover:text-primary hover:cursor-pointer text-[14px] md:text-base">
                    <a href="/privacy">Privacy and Terms</a>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        `;
    }
}

customElements.define('blockonomics-footer', BlockonomicsFooter);
