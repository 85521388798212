import {LitElement, html} from "lit";

export class ThemeSwitcher extends LitElement {
    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            <div class="fixed bottom-2 right-2 bg-slate-300 dark:bg-slate-800 p-2 rounded-lg cursor-pointer">
                <button class="flex justify-center items-center hover:bg-slate-100 dark:hover:bg-secondary p-1 rounded mb-1"
                        @click="${this.toggleTheme}">
                    <svg id="theme-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke-width="1.5" stroke="currentColor"
                         class="w-4 h-4 dark:fill-gray-500">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"/>
                    </svg>
                    <svg id="theme-light" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke-width="1.5"
                         stroke="currentColor"
                         class="w-4 h-4 hidden">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"/>
                    </svg>
                </button>
                <button class="flex justify-center items-center hover:bg-slate-100 dark:hover:bg-secondary p-1 rounded"
                        @click="${this.themeSystemPreference}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                         stroke="currentColor"
                         class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"/>
                    </svg>
                </button>
            </div>
        `;
    }

    toggleTheme() {
        let isDarkTheme = localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
        localStorage.theme = isDarkTheme ? 'light' : 'dark';
        if (isDarkTheme) {
            document.getElementById('theme-dark').classList.remove(['hidden']);
            document.getElementById('theme-light').classList.add(['hidden']);
        } else {
            document.getElementById('theme-dark').classList.add(['hidden']);
            document.getElementById('theme-light').classList.remove(['hidden']);
        }
        this.updateTheme();
    }

    themeSystemPreference() {
        localStorage.removeItem('theme');
        this.updateTheme();
    }

    updateTheme() {
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }
}

customElements.define('theme-switcher', ThemeSwitcher);