import {html} from "lit";

export const cart = html`
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2 0.214279C1.6969 0.214279 1.40621 0.334687 1.19188 0.549014C0.977555 0.763341 0.857147 1.05403 0.857147 1.35714C0.857147 1.66024 0.977555 1.95093 1.19188 2.16526C1.40621 2.37959 1.6969 2.49999 2 2.49999H3.39429L3.74286 3.89656C3.74632 3.91265 3.75013 3.92865 3.75429 3.94456L5.30629 10.1503L4.28572 11.1697C2.84572 12.6097 3.86515 15.0714 5.90172 15.0714H15.7143C16.0174 15.0714 16.3081 14.951 16.5224 14.7367C16.7367 14.5224 16.8571 14.2317 16.8571 13.9286C16.8571 13.6255 16.7367 13.3348 16.5224 13.1204C16.3081 12.9061 16.0174 12.7857 15.7143 12.7857H5.90172L7.04458 11.6428H14.5714C14.7836 11.6427 14.9916 11.5836 15.172 11.4719C15.3525 11.3603 15.4983 11.2007 15.5931 11.0108L19.0217 4.15371C19.1088 3.97951 19.1499 3.78596 19.1411 3.59143C19.1323 3.39689 19.074 3.20782 18.9717 3.04216C18.8693 2.87649 18.7263 2.73973 18.5563 2.64484C18.3862 2.54996 18.1947 2.5001 18 2.49999H5.74858L5.39429 1.07942C5.33238 0.832283 5.18966 0.612918 4.98881 0.456171C4.78796 0.299424 4.54049 0.214286 4.28572 0.214279H2ZM16.8571 17.9286C16.8571 18.3832 16.6765 18.8193 16.355 19.1407C16.0336 19.4622 15.5975 19.6428 15.1429 19.6428C14.6882 19.6428 14.2522 19.4622 13.9307 19.1407C13.6092 18.8193 13.4286 18.3832 13.4286 17.9286C13.4286 17.4739 13.6092 17.0379 13.9307 16.7164C14.2522 16.3949 14.6882 16.2143 15.1429 16.2143C15.5975 16.2143 16.0336 16.3949 16.355 16.7164C16.6765 17.0379 16.8571 17.4739 16.8571 17.9286ZM6 19.6428C6.45466 19.6428 6.8907 19.4622 7.21219 19.1407C7.53368 18.8193 7.71429 18.3832 7.71429 17.9286C7.71429 17.4739 7.53368 17.0379 7.21219 16.7164C6.8907 16.3949 6.45466 16.2143 6 16.2143C5.54535 16.2143 5.10931 16.3949 4.78782 16.7164C4.46633 17.0379 4.28572 17.4739 4.28572 17.9286C4.28572 18.3832 4.46633 18.8193 4.78782 19.1407C5.10931 19.4622 5.54535 19.6428 6 19.6428V19.6428Z"
        fill="currentColor"/>
  </svg>`;
export const tick = html`
  <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2.04706 11C2.04706 5.55602 6.46025 1.14282 11.9042 1.14282C17.3482 1.14282 21.7613 5.55602 21.7613 11C21.7613 16.444 17.3482 20.8571 11.9042 20.8571C6.46025 20.8571 2.04706 16.444 2.04706 11Z"
        stroke="currentColor" stroke-width="2.19048" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.52325 11L10.809 14.2857L16.2852 8.80948" stroke="currentColor" stroke-width="2.19048"
          stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`;
export const copy = html`
  <svg viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14 0H2C0.9 0 0 0.9 0 2V16H2V2H14V0ZM17 4H6C4.9 4 4 4.9 4 6V20C4 21.1 4.9 22 6 22H17C18.1 22 19 21.1 19 20V6C19 4.9 18.1 4 17 4ZM17 20H6V6H17V20Z"
        fill="currentColor"/>
  </svg>`;
export const refresh = html`
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15.68 7.04H20M20 7.04C20 7.04 16.4 2 11 2C6.02912 2 2 5.6 2 9.92M20 7.04L20 2M6.32 14.96H2M2 14.96C2 14.96 5.6 20 11 20C15.9709 20 20 16.4 20 12.08M2 14.96V20"
        stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`;

export const close = html`
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_357_1984" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_357_1984)">
      <path
          d="M12 13.0538L6.92689 18.1269C6.78844 18.2654 6.6144 18.3362 6.40479 18.3394C6.19519 18.3426 6.01795 18.2718 5.87309 18.1269C5.7282 17.982 5.65576 17.8064 5.65576 17.6C5.65576 17.3936 5.7282 17.218 5.87309 17.0731L10.9462 12L5.87309 6.9269C5.73462 6.78845 5.66379 6.61442 5.66059 6.4048C5.65737 6.1952 5.7282 6.01797 5.87309 5.8731C6.01795 5.72822 6.19359 5.65578 6.39999 5.65578C6.60639 5.65578 6.78202 5.72822 6.92689 5.8731L12 10.9462L17.0731 5.8731C17.2115 5.73464 17.3856 5.6638 17.5952 5.6606C17.8048 5.65739 17.982 5.72822 18.1269 5.8731C18.2718 6.01797 18.3442 6.1936 18.3442 6.4C18.3442 6.6064 18.2718 6.78203 18.1269 6.9269L13.0538 12L18.1269 17.0731C18.2654 17.2116 18.3362 17.3856 18.3394 17.5952C18.3426 17.8048 18.2718 17.982 18.1269 18.1269C17.982 18.2718 17.8064 18.3442 17.6 18.3442C17.3936 18.3442 17.218 18.2718 17.0731 18.1269L12 13.0538Z"
          fill="black"/>
    </g>
  </svg>
`;

export const whiteClose = html`
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_357_1984" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_357_1984)">
      <path
          d="M12 13.0538L6.92689 18.1269C6.78844 18.2654 6.6144 18.3362 6.40479 18.3394C6.19519 18.3426 6.01795 18.2718 5.87309 18.1269C5.7282 17.982 5.65576 17.8064 5.65576 17.6C5.65576 17.3936 5.7282 17.218 5.87309 17.0731L10.9462 12L5.87309 6.9269C5.73462 6.78845 5.66379 6.61442 5.66059 6.4048C5.65737 6.1952 5.7282 6.01797 5.87309 5.8731C6.01795 5.72822 6.19359 5.65578 6.39999 5.65578C6.60639 5.65578 6.78202 5.72822 6.92689 5.8731L12 10.9462L17.0731 5.8731C17.2115 5.73464 17.3856 5.6638 17.5952 5.6606C17.8048 5.65739 17.982 5.72822 18.1269 5.8731C18.2718 6.01797 18.3442 6.1936 18.3442 6.4C18.3442 6.6064 18.2718 6.78203 18.1269 6.9269L13.0538 12L18.1269 17.0731C18.2654 17.2116 18.3362 17.3856 18.3394 17.5952C18.3426 17.8048 18.2718 17.982 18.1269 18.1269C17.982 18.2718 17.8064 18.3442 17.6 18.3442C17.3936 18.3442 17.218 18.2718 17.0731 18.1269L12 13.0538Z"
          fill="white"/>
    </g>
  </svg>
`;

export const closedIcon = html`
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Chevrons">
      <mask id="mask0_72_637" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="41">
        <rect id="Bounding box" x="0.5" y="0.212891" width="40" height="40" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_72_637)">
        <path id="expand_more"
              d="M20.4999 24.6487C20.299 24.6487 20.1121 24.6167 19.939 24.5526C19.7659 24.4885 19.6014 24.3784 19.4454 24.2224L11.955 16.7321C11.7243 16.5013 11.6062 16.2113 11.6009 15.8619C11.5955 15.5125 11.7136 15.2171 11.955 14.9757C12.1965 14.7342 12.4892 14.6135 12.8332 14.6135C13.1772 14.6135 13.4699 14.7342 13.7114 14.9757L20.4999 21.7642L27.2884 14.9757C27.5191 14.7449 27.8092 14.6269 28.1585 14.6215C28.5079 14.6162 28.8033 14.7342 29.0447 14.9757C29.2862 15.2171 29.4069 15.5099 29.4069 15.8539C29.4069 16.1979 29.2862 16.4906 29.0447 16.7321L21.5543 24.2224C21.3984 24.3784 21.2338 24.4885 21.0608 24.5526C20.8877 24.6167 20.7007 24.6487 20.4999 24.6487Z"
              fill="black"/>
      </g>

    </g>
  </svg>
`;
export const closedIconWhite = html`
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Chevrons">
      <mask id="mask0_72_637" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="41">
        <rect id="Bounding box" x="0.5" y="0.212891" width="40" height="40" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_72_637)">
        <path id="expand_more"
              d="M20.4999 24.6487C20.299 24.6487 20.1121 24.6167 19.939 24.5526C19.7659 24.4885 19.6014 24.3784 19.4454 24.2224L11.955 16.7321C11.7243 16.5013 11.6062 16.2113 11.6009 15.8619C11.5955 15.5125 11.7136 15.2171 11.955 14.9757C12.1965 14.7342 12.4892 14.6135 12.8332 14.6135C13.1772 14.6135 13.4699 14.7342 13.7114 14.9757L20.4999 21.7642L27.2884 14.9757C27.5191 14.7449 27.8092 14.6269 28.1585 14.6215C28.5079 14.6162 28.8033 14.7342 29.0447 14.9757C29.2862 15.2171 29.4069 15.5099 29.4069 15.8539C29.4069 16.1979 29.2862 16.4906 29.0447 16.7321L21.5543 24.2224C21.3984 24.3784 21.2338 24.4885 21.0608 24.5526C20.8877 24.6167 20.7007 24.6487 20.4999 24.6487Z"
              fill="white"/>
      </g>

    </g>
  </svg>
`;


export const doubleRightAngle = html`
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_726_3624" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect x="24" y="24" width="24" height="24" transform="rotate(-180 24 24)" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_726_3624)">
      <path
          d="M10.3953 12.1321L6.33761 8.05897C6.19914 7.92052 6.12574 7.74905 6.11741 7.54457C6.10907 7.34008 6.18247 7.16029 6.33761 7.00517C6.48247 6.86029 6.65811 6.78784 6.86451 6.78784C7.07091 6.78784 7.24654 6.86029 7.39141 7.00517L11.4395 11.0532C11.7331 11.3468 11.8799 11.7064 11.8799 12.1321C11.8799 12.5577 11.7331 12.9173 11.4395 13.2109L7.39141 17.259C7.25296 17.3974 7.08149 17.4708 6.87701 17.4792C6.67252 17.4875 6.49272 17.4141 6.33761 17.259C6.19272 17.1141 6.12028 16.9385 6.12028 16.7321C6.12028 16.5257 6.19272 16.35 6.33761 16.2052L10.3953 12.1321ZM16.3953 12.1321L12.3376 8.05897C12.1991 7.92052 12.1257 7.74905 12.1174 7.54457C12.1091 7.34009 12.1825 7.16029 12.3376 7.00517C12.4825 6.86029 12.6581 6.78784 12.8645 6.78784C13.0709 6.78784 13.2465 6.86029 13.3914 7.00517L17.4395 11.0532C17.7331 11.3468 17.8799 11.7064 17.8799 12.1321C17.8799 12.5577 17.7331 12.9173 17.4395 13.2109L13.3914 17.259C13.253 17.3974 13.0815 17.4708 12.877 17.4792C12.6725 17.4875 12.4927 17.4141 12.3376 17.259C12.1927 17.1141 12.1203 16.9385 12.1203 16.7321C12.1203 16.5257 12.1927 16.35 12.3376 16.2052L16.3953 12.1321Z"
          fill="black"/>
    </g>
  </svg>
`

export const openedIcon = html`
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Chevrons">
      <mask id="mask0_72_866" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="40">
        <rect id="Bounding box" x="0.5" width="40" height="40" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_72_866)">
        <path id="expand_more"
              d="M20.5078 14.4007C20.7087 14.4007 20.8956 14.4328 21.0687 14.4969C21.2418 14.561 21.4063 14.671 21.5623 14.827L29.0526 22.3174C29.2834 22.5481 29.4015 22.8382 29.4068 23.1875C29.4122 23.5369 29.2941 23.8323 29.0526 24.0737C28.8112 24.3152 28.5185 24.4359 28.1745 24.4359C27.8305 24.4359 27.5378 24.3152 27.2963 24.0737L20.5078 17.2852L13.7193 24.0737C13.4886 24.3045 13.1985 24.4226 12.8491 24.4279C12.4998 24.4332 12.2044 24.3152 11.963 24.0737C11.7215 23.8323 11.6008 23.5396 11.6008 23.1955C11.6008 22.8515 11.7215 22.5588 11.963 22.3174L19.4533 14.827C19.6093 14.671 19.7738 14.561 19.9469 14.4969C20.12 14.4328 20.3069 14.4007 20.5078 14.4007Z"
              fill="black"/>
      </g>
    </g>
  </svg>
`;
export const openedIconWhite = html`
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Chevrons">
      <mask id="mask0_72_866" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="40">
        <rect id="Bounding box" x="0.5" width="40" height="40" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_72_866)">
        <path id="expand_more"
              d="M20.5078 14.4007C20.7087 14.4007 20.8956 14.4328 21.0687 14.4969C21.2418 14.561 21.4063 14.671 21.5623 14.827L29.0526 22.3174C29.2834 22.5481 29.4015 22.8382 29.4068 23.1875C29.4122 23.5369 29.2941 23.8323 29.0526 24.0737C28.8112 24.3152 28.5185 24.4359 28.1745 24.4359C27.8305 24.4359 27.5378 24.3152 27.2963 24.0737L20.5078 17.2852L13.7193 24.0737C13.4886 24.3045 13.1985 24.4226 12.8491 24.4279C12.4998 24.4332 12.2044 24.3152 11.963 24.0737C11.7215 23.8323 11.6008 23.5396 11.6008 23.1955C11.6008 22.8515 11.7215 22.5588 11.963 22.3174L19.4533 14.827C19.6093 14.671 19.7738 14.561 19.9469 14.4969C20.12 14.4328 20.3069 14.4007 20.5078 14.4007Z"
              fill="white"/>
      </g>
    </g>
  </svg>
`;

